var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c("v-progress-circular", {
                staticClass: "progress",
                attrs: { size: 50, color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : [
            _c(
              "v-banner",
              [
                !_vm.tripRequest.submittedUser
                  ? [
                      _c(
                        "h4",
                        { staticClass: "text-h4" },
                        [
                          _vm._v(" Trip Request Details"),
                          _c("v-badge", {
                            attrs: {
                              value: !_vm.tripRequest.id,
                              color: "error",
                              content: "New",
                              overlap: "",
                              "offset-x": "-10px",
                              "offset-y": "-10px",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-label", [_vm._v("Create or edit Trip Request")]),
                    ]
                  : _vm.tripRequest.status == -1 &&
                    _vm.tripRequest.submittedUser
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-alert",
                          {
                            staticClass: "amber--text text--darken-1 mb-0",
                            attrs: {
                              outlined: "",
                              icon: "mdi-alert",
                              text: "",
                              prominent: "",
                              dense: "",
                            },
                          },
                          [
                            _c(
                              "h6",
                              { staticClass: "text-h6 d-inline-block mr-5" },
                              [
                                _vm._v(
                                  " Changes Requested by " +
                                    _vm._s(
                                      _vm.tripRequest.approval?.audit?.[0]
                                        ?.username
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("span", [
                              _vm._v(
                                "Please make the required changes and resubmit this trip request."
                              ),
                            ]),
                            _c("br"),
                            _c("v-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.tripRequest.approval?.audit?.[0]?.comment
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.tripRequest.approval &&
                    _vm.tripRequest.approval.requiredApprovalLevels &&
                    _vm.tripRequest.approval.requiredApprovalLevels.length
                  ? _c(
                      "div",
                      { staticClass: "level-container" },
                      [
                        _vm._l(
                          _vm.tripRequest.approval.requiredApprovalLevels,
                          function (level, i) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  key: i,
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    key: i,
                                                    class: `subtitle-2 level ${
                                                      _vm.levelColors[i] ==
                                                      "blue"
                                                        ? "font-weight-bold"
                                                        : ""
                                                    }`,
                                                    style: `color: ${
                                                      _vm.tripRequest.approval
                                                        .approved
                                                        ? "success"
                                                        : _vm.levelColors[i]
                                                    }`,
                                                  },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.tripRequest
                                                            .approval
                                                            .approved ||
                                                          _vm.levelColors[i] ==
                                                            "green",
                                                        expression:
                                                          "tripRequest.approval.approved || levelColors[i] == 'green'",
                                                      },
                                                    ],
                                                    staticClass: "mr-1",
                                                    attrs: { color: "green" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-checkbox-marked-circle "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " + _vm._s(level.name) + " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.levelTooltips[i]),
                                    },
                                  }),
                                ]
                              ),
                              i <
                              _vm.tripRequest.approval.requiredApprovalLevels
                                .length -
                                1
                                ? _c("v-icon", { key: i + 5000 }, [
                                    _vm._v(" mdi-arrow-right-bold "),
                                  ])
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-card",
                  {
                    class:
                      _vm.tripRequest.status == 1 ||
                      _vm.tripRequest.status == -3
                        ? "form nav-padding"
                        : "form",
                  },
                  [
                    _vm.tripRequest.status == 1 || _vm.tripRequest.status == -3
                      ? _c(
                          "v-navigation-drawer",
                          {
                            attrs: {
                              "mini-variant": "",
                              permanent: "",
                              absolute: "",
                              right: "",
                            },
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { nav: "" } },
                              _vm._l(_vm.sideNav, function (item) {
                                return _c(
                                  "v-tooltip",
                                  {
                                    key: item.name,
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        link: "",
                                                        ripple: false,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setCard(
                                                            item.name
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-list-item",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c(
                                                        "v-badge",
                                                        {
                                                          attrs: {
                                                            content: item.num,
                                                            value: !!item.num,
                                                            color: "red",
                                                            overlap: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  _vm.cardContent ==
                                                                  item.name
                                                                    ? "primary"
                                                                    : "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.icon
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.label))])]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.cardContent == "tripRequest"
                      ? _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              { staticClass: "form-wrapper" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "form-content",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    !_vm.duplicated
                                      ? _c(
                                          "div",
                                          { staticClass: "title" },
                                          [
                                            _vm.tripRequest.status == -2 ||
                                            _vm.tripRequest.status == -3
                                              ? _c(
                                                  "v-alert",
                                                  {
                                                    attrs: {
                                                      color: "red lighten-2",
                                                      outlined: "",
                                                      dense: "",
                                                      text: "",
                                                      type: "warning",
                                                      prominent: "",
                                                    },
                                                  },
                                                  [
                                                    _c("h2", [
                                                      _vm._v(
                                                        "This trip has been " +
                                                          _vm._s(
                                                            _vm.tripRequest
                                                              .status == -2
                                                              ? "denied"
                                                              : "canceled"
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "h6",
                                              { staticClass: "text-h6 inline" },
                                              [
                                                _vm._v(
                                                  "Trip Request #" +
                                                    _vm._s(_vm.tripRequest.id)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-subtitle-2 inline ml-2",
                                              },
                                              [_vm._v(_vm._s(_vm.submitted))]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.tripRequest.status == 1 &&
                                    !_vm.tripRequest.approval.approved &&
                                    _vm.tripRequest.recurrence.length > 0
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "v-alert",
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  type: "warning",
                                                  icon: "mdi-autorenew",
                                                  text: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " This trip is recurring. Once approved a duplicate trip will be created for each of the selected dates. "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "section" },
                                      [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v("General"),
                                        ]),
                                        _c("step-general", {
                                          ref: "stepGeneral",
                                          attrs: {
                                            tripRequest: _vm.tripRequest,
                                            tripRequestConfig:
                                              _vm.tripRequestConfig,
                                            customFormFields:
                                              _vm.filteredCFFs[0],
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "section" },
                                      [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v("Leave / Return"),
                                        ]),
                                        _c("step-leave-return", {
                                          attrs: {
                                            tripRequest: _vm.tripRequest,
                                            tripRequestConfig:
                                              _vm.tripRequestConfig,
                                            customFormFields:
                                              _vm.filteredCFFs[1],
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "section" },
                                      [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v("Destination(s)"),
                                        ]),
                                        _c("step-destination", {
                                          staticClass: "section",
                                          attrs: {
                                            customFormFields:
                                              _vm.filteredCFFs[2],
                                            tripRequest: _vm.tripRequest,
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "section" },
                                      [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v("Attendees"),
                                        ]),
                                        _c("step-attendees", {
                                          staticClass: "section",
                                          attrs: {
                                            tripRequest: _vm.tripRequest,
                                            tripRequestConfig:
                                              _vm.tripRequestConfig,
                                            customFormFields:
                                              _vm.filteredCFFs[3],
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "section" },
                                      [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v("Transportation"),
                                        ]),
                                        _c("step-transportation", {
                                          staticClass: "section",
                                          attrs: {
                                            tripRequest: _vm.tripRequest,
                                            tripRequestConfig:
                                              _vm.tripRequestConfig,
                                            customFormFields:
                                              _vm.filteredCFFs[4],
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "section" },
                                      [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v("Funding"),
                                        ]),
                                        _c("step-funding-sources", {
                                          staticClass: "section",
                                          attrs: {
                                            tripRequest: _vm.tripRequest,
                                            tripRequestConfig:
                                              _vm.tripRequestConfig,
                                            customFormFields:
                                              _vm.filteredCFFs[5],
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _vm.showAdditionalInfo
                                      ? _c(
                                          "div",
                                          { staticClass: "section" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "text-h6" },
                                              [_vm._v("Additional Info")]
                                            ),
                                            _c("step-additional-info", {
                                              staticClass: "section",
                                              attrs: {
                                                tripRequest: _vm.tripRequest,
                                                tripRequestConfig:
                                                  _vm.tripRequestConfig,
                                                customFormFields:
                                                  _vm.filteredCFFs[6],
                                                readonly: "",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showAdditionalInfo
                                      ? _c("v-divider")
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "section" },
                                      [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v("Supporting Documents"),
                                        ]),
                                        _c("step-supporting-documents", {
                                          staticClass: "section",
                                          attrs: {
                                            tripRequest: _vm.tripRequest,
                                            customFormFields:
                                              _vm.filteredCFFs[7],
                                            readonly: "",
                                          },
                                          on: {
                                            refresh: _vm.refreshTripRequest,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.cardContent == "assignments"
                      ? _c(
                          "v-card-text",
                          { staticClass: "card-content" },
                          [
                            _c("trip-request-assignments", {
                              ref: "assignments",
                              attrs: {
                                assignments: _vm.tripRequest.assignments,
                                tripRequest: _vm.tripRequest,
                                tripRequestConfig: _vm.tripRequestConfig,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.cardContent == "comments"
                      ? _c(
                          "v-card-text",
                          { staticClass: "card-content" },
                          [
                            _c("trip-request-comments", {
                              ref: "comments",
                              attrs: {
                                comments: _vm.tripRequest.userComments,
                                tripRequestId: _vm.tripRequest.id,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.cardContent == "emails"
                      ? _c(
                          "v-card-text",
                          { staticClass: "card-content" },
                          [
                            _c("trip-request-emails", {
                              ref: "emails",
                              attrs: {
                                emails: _vm.tripRequest.userEmails,
                                tripRequestId: _vm.tripRequest.id,
                                requesterId: _vm.tripRequest.submittedUser,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.cardContent == "audit"
                      ? _c(
                          "v-card-text",
                          { staticClass: "card-content" },
                          [
                            _c("trip-request-audit", {
                              ref: "audit",
                              attrs: {
                                auditHistory: _vm.tripRequest.auditHistory,
                                approval: _vm.tripRequest.approval,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }